import React from 'react';

import {GetStaticPaths, GetStaticProps} from 'next';

import {WebPage} from 'src/components/contentful';
import {LayoutProps} from 'src/components/layout/Layout';
import {regionalConfigsArr} from 'src/config';
import PageNotFound from 'src/pages/404';
import {PageParams} from 'src/types';
import {contentfulApi} from 'src/utils/contentful/client';
import {IWebPage} from 'src/utils/contentful/content-models/generated/contentful';
import {
  indexSlugs,
  getImageAltFromAsset,
  getImageSourceFromAsset,
} from 'src/utils/contentful/content-models/util';

interface HomePageBaseProps extends LayoutProps {
  notFound: false;
  page: IWebPage;
}

type HomePageProps = HomePageBaseProps | {notFound: true};

export const getStaticPaths: GetStaticPaths<PageParams> = async () => {
  const paths: {params: PageParams}[] = [];

  for (const config of regionalConfigsArr) {
    const {locales, regionCode: region} = config;
    for (const locale of locales) {
      paths.push({params: {region, locale}});
    }
  }

  return {paths, fallback: false};
};

export const getStaticProps: GetStaticProps<HomePageProps, PageParams> = async (
  ctx
) => {
  const {params, preview} = ctx;

  if (!params) {
    throw new Error('Could not find page params');
  }

  const {region, locale} = params;

  const slug = indexSlugs.index;
  const api = preview ? contentfulApi.preview : contentfulApi;
  const page = await api.getWebPage(slug, {locale});

  if (!page) {
    return {props: {notFound: true}};
  }

  const {title, description, image, includeInRegions} = page.fields;

  return {
    props: {
      notFound: false,
      page,
      pageContext: {
        region,
        locale,
      },
      seo: {
        slug,
        title,
        description,
        includeInRegions,
        imageSrc: getImageSourceFromAsset(image),
        imageAltText: getImageAltFromAsset(image),
        slugPrefix: '',
        noIndex: false,
      },
      preview: !!preview,
    },
  };
};

const HomePage: React.FC<HomePageProps> = (props) => {
  if (props.notFound) {
    return <PageNotFound />;
  }

  const {pageContext, seo, preview, page} = props;
  return (
    <WebPage
      pageEntry={page}
      pageContext={pageContext}
      seo={seo}
      preview={preview}
    />
  );
};

export default HomePage;
